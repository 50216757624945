/* eslint-disable @typescript-eslint/typedef */
import { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import './index.less'

interface IProps {
  max: number;
  swiperData: any[];
}

function Index ({ swiperData, max }: IProps): JSX.Element {

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const changeIndex = (type: 'down' | 'up'): void => {
    if (type === 'up') {
      setCurrentIndex(currentIndex >= max ? 0 : currentIndex + 1)
    } else if (type === 'down') {
      setCurrentIndex(currentIndex <= 0 ? max : currentIndex - 1)
    }
  }

  return (
    <div>
      <div className="mobile-swiper-component">
        <div className="swiper-box-xs">
          <SwipeableViews
            enableMouseEvents
            index={currentIndex}
            style={{ marginTop: 10 }}
          >
            {swiperData?.map((i: string, index: number) => {
              return <img className="img-xs" src={i} key={index} />
            })}
          </SwipeableViews>
          <img
            onClick={(): void => {
              changeIndex('down')
            }}
            className="arrow-left-xs arrow-xs"
            src="//img3.dian.so/lhc/2021/11/05/32w_54h_DD1BF1636106095.png"
          />
          <img
            onClick={(): void => {
              changeIndex('up')
            }}
            className="arrow-right-xs arrow-xs"
            src="//img3.dian.so/lhc/2021/11/05/32w_54h_75CB91636106425.png"
          />
        </div>
      </div>
    </div>
  )
}

export default Index
